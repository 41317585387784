import './App.css';
import BuildWithUs from './components/build_with_us/BuildWithUs';
import Header from './components/header/Header';
import Services from './components/services/Services';
import Special from './components/special/Special';
import Process from './components/process/Process';
import AboutUs from './components/about_us/AboutUs';
import ContactUs from './components/contact_us/ContactUs';
import Footer from './components/footer/Footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


function App() {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route path='/' exact />
        </Routes>

        <Services />
        <Routes>
          <Route path='/services' exact />
        </Routes>

        <BuildWithUs />
        <Routes>
          <Route path='/buildwithus' exact />
        </Routes>

        <Special />
        <Routes>
          <Route path='/special' exact />
        </Routes>

        <Process/>
        <Routes>
          <Route path='/process' exact />
        </Routes>

        <AboutUs/> 
        <Routes>
          <Route path='/aboutus' exact />
        </Routes>

        <ContactUs/> 
        <Routes>
          <Route path='/contact' exact />
        </Routes>

        <Footer/> 
        <Routes>
          <Route path='/footer' exact />
        </Routes>
      </Router>
    </>
  );
}

export default App;
