import React from 'react';
import './AboutUs.css'

function AboutUs() {
    return (
        <section className='about-us'>
            <div className='about-us-wrapper'>
                <div className='about-us-main-content'>
                    <div className='about-us-logo-wrapper'>
                        <div className='about-us-logo'></div>
                    </div>
                    <div className='about-us-text'>
                        <div className='about-us-text-paragraph-1'>
                            <p>
                                About Us
                            </p>
                        </div>
                        <div className='about-us-text-paragraph-2'>
                            <p>
                                Choose from an unrivaled set of best-in-class
                                website templates. Customize the design to fit
                                your personal style and professional
                                needs. Pellentesque dignissim, nulla vel
                                interdum eleifend, elit sem molestie leo, ut
                                commodo diam nibh a turpis. Integer posuere
                                egestas lobortis. Aliquam bibendum quam vel
                                purus mollis, vel tempus eros mattis. Duis
                                bibendum volutpat felis, id vulputate nisl
                                tristique sit amet. Sed vitae risus mi.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutUs