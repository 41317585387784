import React from 'react';
import './BuildWithUs.css'

function BuildWithUs() {
    return (
        <section className='build-with-us'>
            <div className='build-with-us-wrapper'>
                <div className='build-with-us-main-content'>
                    <div className='build-with-us-paragraph-1'>
                        <p>
                            Want to Build Great Product
                            With Us?
                        </p>
                    </div>
                    <button className='build-with-us-button'>
                        CLICK HERE
                    </button>
                </div>
            </div>
        </section>
    )
}

export default BuildWithUs