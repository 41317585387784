import React from 'react';
import './Special.css'

function Special() {
    return (
        <section className='special'>
            <div className='special-wrapper'>
                <div className='special-main-content'>
                    <div className='special-description'>
                        <div className='special-description-paragraph-1'>
                            <p>
                                What makes us a special agency
                            </p>
                        </div>
                        <div className='special-description-paragraph-2'>
                            <p>
                                Choose from an inrivaled set of best-in-class
                                website templates. Customize the design to fit your
                                personal style and professional needs.
                            </p>
                        </div>
                    </div>
                    <div className='special-icons'>
                        <div className='special-icons-1-image'></div>
                        <div className='special-icons-text'>
                            <div className='special-icons-paragraph-1'>
                                <p>
                                    HIGH QUALITY
                                </p>
                            </div>
                            <div className='special-icons-paragraph-2'>
                                <p>
                                    Customize the design to fit your
                                    personal style
                                </p>
                            </div>
                        </div>
                        <div className='special-icons-2-image'></div>
                        <div className='special-icons-text'>
                            <div className='special-icons-paragraph-1'>
                                <p>
                                    KNOWLEDGE
                                </p>
                            </div>
                            <div className='special-icons-paragraph-2'>
                                <p>
                                    Customize the design to fit your
                                    personal style
                                </p>
                            </div>
                        </div>
                        <div className='special-icons-3-image'></div>
                        <div className='special-icons-text'>
                            <div className='special-icons-paragraph-1'>
                                <p>
                                    ACCURACY
                                </p>
                            </div>
                            <div className='special-icons-paragraph-2'>
                                <p>
                                    Customize the design to fit your
                                    personal style
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Special