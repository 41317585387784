import React from 'react';
import './Footer.css'

function Footer() {
    return (
        <footer className='footer'>
            <div className='footer-wrapper'>
                <div className='footer-main-content'>
                    <div className='footer-logo'></div>
                    <div className='footer-text'>
                        <div className='footer-text-services'>
                            <div className='footer-text-paragraph-1'>
                                <p>
                                    SERVICES
                                </p>
                            </div>
                            <div className='footer-text-paragraph-2'>
                                <p>
                                    ChatBot
                                </p>
                            </div>
                            <div className='footer-text-paragraph-2'>
                                <p>
                                    AI
                                </p>
                            </div>
                            <div className='footer-text-paragraph-2'>
                                <p>
                                    Other
                                </p>
                            </div>
                        </div>
                        <div className='footer-text-legal'>
                            <div className='footer-text-paragraph-1'>
                                <p>
                                    LEGAL
                                </p>
                            </div>
                            <div className='footer-text-paragraph-2'>
                                <p>
                                    Terms of use
                                </p>
                            </div>
                            <div className='footer-text-paragraph-2'>
                                <p>
                                    License agreement
                                </p>
                            </div>
                            <div className='footer-text-paragraph-2'>
                                <p>
                                    Privacy policy
                                </p>
                            </div>
                            <div className='footer-text-paragraph-2'>
                                <p>
                                    Copyright information
                                </p>
                            </div>
                        </div>
                        <div className='footer-text-aboutus-contact'>
                            <div className='footer-text-paragraph-1'>
                                <p>
                                    ABOUT US
                                </p>
                            </div>
                            <div className='footer-padding'></div>
                            <div className='footer-text-paragraph-1'>
                                <p>
                                    CONTACT
                                </p>
                            </div>
                            <div className='footer-text-paragraph-2'>
                                <p>
                                    mailaddress@gmail.com
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='footer-copyright-wrapper'>
                    <div className='footer-copyright'></div>
                </div>
            </div>
        </footer>
    )
}

export default Footer